import { zodResolver } from '@hookform/resolvers/zod';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { Loader2 } from 'lucide-react';
import { signIn } from 'next-auth/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import Logo from 'components/Logo/Logo';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp';
import { Separator } from 'components/ui/separator';

import { useSvgIcon } from 'growl/hooks';

export interface LoginViewProps {
  isLoading: boolean;
  isNewUser: boolean;
  setNewUser: (isNewUser: boolean) => void;
  hasOTP: boolean;
  setOTP: (hasOTP: boolean) => void;
  onSendOTP: (values) => void;
  onSignInWithPin: (values) => void;
}

const defaultProps: Partial<LoginViewProps> = {};

const formSchema = z
  .object({
    email: z.string().email(),
    name: z.string(),
    pin: z.string(),
    isNewUser: z.boolean(),
  })
  .refine(
    data => {
      if (data.isNewUser && !data.name) {
        return false;
      }
      return true;
    },
    {
      message: 'Name is required for new users',
      path: ['name'],
    }
  );

const formValueDefaults = {
  email: '',
  name: '',
  pin: '',
  isNewUser: false,
};

const LoginView: React.FC<LoginViewProps> = ({
  isLoading,
  isNewUser,
  setNewUser,
  hasOTP,
  setOTP,
  onSendOTP,
  onSignInWithPin,
}) => {
  const Google = useSvgIcon('google');

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...formValueDefaults,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (values.email && !hasOTP) {
      onSendOTP({ email: values.email.toLowerCase() });
    } else {
      onSignInWithPin({ ...values, email: values.email.toLowerCase() });
    }
  };

  useEffect(() => {
    form.setValue('isNewUser', isNewUser);
  }, [form, isNewUser]);

  return (
    <div className={'min-h-screen grid place-items-center'}>
      <div />
      <div>
        <Logo className={'w-16 my-4 mx-auto'} />
        <div className={'w-72 flex flex-col'}>
          <h1
            className={'pb-6 text-3xl font-semibold tracking-tight text-center'}
          >
            Welcome to Euryka
          </h1>
          <h5 className="pb-2 text-sm font-medium tracking-tight text-muted-foreground">
            Login / Signup
          </h5>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        type={'email'}
                        placeholder="email"
                        {...field}
                        onChange={e => {
                          setNewUser(false);
                          setOTP(null);
                          field.onChange(e);
                        }}
                      />
                    </FormControl>
                    <FormDescription></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {hasOTP && isNewUser && (
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input type={'text'} placeholder="name" {...field} />
                      </FormControl>
                      <FormDescription></FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {hasOTP && (
                <FormField
                  control={form.control}
                  name="pin"
                  disabled={isLoading}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>OTP</FormLabel>
                      <FormControl>
                        <InputOTP
                          maxLength={8}
                          pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                          {...field}
                        >
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                            <InputOTPSlot index={6} />
                            <InputOTPSlot index={7} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormDescription>
                        Please enter the one-time password sent to your email.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {!hasOTP && (
                <Button
                  type="submit"
                  className={'mt-4 w-full'}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Continue
                </Button>
              )}
              {hasOTP && (
                <>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    className={'mt-4 w-full'}
                  >
                    {isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Continue
                  </Button>
                  <Button
                    type="button"
                    onClick={async () => {
                      await onSendOTP({ email: form.getValues('email') });
                      form.setValue('pin', '');
                    }}
                    variant={'link'}
                    disabled={isLoading}
                    className={'mt-4 w-full text-muted-foreground h-fit py-0'}
                  >
                    Resend OTP
                  </Button>
                </>
              )}
            </form>
          </Form>

          <div className={'grid place-items-center'}>
            <Separator
              className={'my-8 col-start-1 col-end-2 row-start-1 row-end-2'}
            />
            <Badge
              variant={'outline'}
              className={
                'border-none col-start-1 col-end-2 row-start-1 row-end-2 bg-muted text-muted-foreground'
              }
            >
              OR
            </Badge>
          </div>

          <Button
            onClick={() => {
              signIn('google');
            }}
            variant={'link'}
            className={'border self-center rounded-full px-4 py-2.5 h-[unset]'}
          >
            <Google.SvgIcon className={'w-4 h-4 mr-2'} />
            Continue with google
          </Button>
        </div>
      </div>
      <div className={'text-sm text-muted-foreground self-end px-4 mb-5'}>
        By clicking {'"Continue"'}, you acknowledge and agree to our
        <Button
          onClick={() => {
            window.open('https://euryka.ai/terms-of-service/', '_blank');
          }}
          variant={'link'}
          className={'px-1 py-0 h-fit'}
        >
          Terms of Service
        </Button>
        and
        <Button
          onClick={() => {
            window.open('https://euryka.ai/privacy-policy/', '_blank');
          }}
          variant={'link'}
          className={'px-1 py-0 h-fit'}
        >
          Privacy Policy.
        </Button>
      </div>
    </div>
  );
};

LoginView.defaultProps = defaultProps;

export default LoginView;

import * as Sentry from '@sentry/react';
import { motion } from 'framer-motion';
import { GetServerSideProps } from 'next';
import { Router } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import LoginView from 'containers/LoginPage/LoginView/LoginView';
import { getCopy } from 'store/copy.data';
import { CopyStoreType } from 'store/copy.types';
import API from 'utils/api';
import { TOAST_DEFAULTS } from 'utils/config';
import { getSessionFromCookie } from 'utils/getSessionFromCookie';
import { Pages, ROUTES } from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';
import { Session } from 'next-auth';

const IS_DEBUG = process.env.IS_DEBUG && true;

export const getServerSideProps: GetServerSideProps = async ctx => {
  try {
    const session = await getSessionFromCookie({
      req: { cookies: ctx.req.cookies },
    });
    if (session) {
      console.log('LoginPage -- getServerSideProps -- session:', session);
      return {
        redirect: {
          destination: session.user.isWhitelist
            ? ROUTES.WORKSPACE
            : ROUTES.HOME,
          permanent: false,
        },
      };
    }

    const { locale } = ctx;
    const initialCopy = getCopy(Pages.login, locale);

    return {
      props: {
        initialCopy,
        initialSession: session,
      },
    };
  } catch (error) {
    Sentry.captureException(error);
    console.log('LoginPage -- getServerSideProps -- error:', error);

    if (process.env.ENV !== 'local') throw new Error(error);
    return { notFound: true };
  }
};

interface LoginPageProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
    global: CopyStoreType['copy']['global'];
    app: CopyStoreType['copy']['app'];
  };
  initialSession: Session;
  router: Router;
}

const LoginPage: React.FC<LoginPageProps> = ({ router }) => {
  const { data: session, status } = useSession();
  const [loading, setLoading] = useState(false);
  const [hasOTP, setOTP] = useState(false);
  const [isNewUser, setNewUser] = useState(true);

  useEffect(() => {
    if (status === 'authenticated' && session) {
      // TODO: updated user
      router.push(session.user.isWhitelist ? ROUTES.WORKSPACE : ROUTES.HOME);
    }
  }, [router, session, status]);

  const onSignInWithPin = useCallback(async values => {
    if (process.env.IS_DEBUG)
      console.log('LoginPage -- onSignInSubmit -- values:', values);
    setLoading(true);
    try {
      const { error } = await signIn('credentials', {
        email: values.email,
        name: values.name,
        pin: values.pin,
        redirect: false,
        callbackUrl: ROUTES.LOGIN,
      });
      if (error) throw error;
    } catch (error) {
      Sentry.captureException(error);
      if (IS_DEBUG) console.log('LoginPage -- onSignInSubmit -- error:', error);
      toast.error('Something went wrong. Please try again.', {
        ...TOAST_DEFAULTS,
        style: {
          top: 0,
        },
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const onSendOTP = useCallback(async values => {
    setLoading(true);
    try {
      const { newUser } = await API.sendOTP({ to: values.email });
      if (process.env.IS_DEBUG)
        console.log('LoginPage -- onSendOTP -- newUser:', newUser);
      setNewUser(newUser);
      toast.success('OTP sent to your email.', {
        ...TOAST_DEFAULTS,
        style: {
          top: 0,
        },
      });
      setOTP(true);
    } catch (error) {
      Sentry.captureException(error);
      if (IS_DEBUG) console.log('LoginPage -- onSendOTP -- error:', error);
      toast.error('Something went wrong. Please try again.', TOAST_DEFAULTS);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <motion.div {...pageMotionProps}>
      <LoginView
        {...{
          isLoading: status === 'loading' || loading,
          isNewUser,
          setNewUser,
          hasOTP,
          setOTP,
          onSendOTP,
          onSignInWithPin,
        }}
      />
    </motion.div>
  );
};

export default LoginPage;
